import GET_ARTICLES from '../class/getArticles';

const [w, d] = [window, document];

class Blocks {
    constructor() {
        this.init();
    }

    init() {
        this.recruit('.guideline-recruit__list[data-plugin="getArticles"]');
    }

    recruit(selector) {
        const recruit_block = new GET_ARTICLES(selector);
        recruit_block.makeItem = (item, content) => `
    <a href="${item.href}" class="guideline-recruit__item">
        <figure class="guideline-recruit__item__img">
          <img src="${item.thumb}" alt="${
            item.title
        }" width="420" loading="lazy">
        </figure>
        <div class="guideline-recruit__item__body">
          <h4 class="subject">
            <span>${item.title.str_truncate(24)}</span>
          </h4>
        </div>
    </a>`;
        // recruit_block.makeItem = (item) => console.log(item);
        // recruit_block.render();
        recruit_block.render().then(() => {
            // ここでcontainer内の記事の数をチェックします
            const articles = d.querySelectorAll(
                `${selector} a.guideline-recruit__item`
            );
            if (articles.length === 0) {
                $('.guideline-recruite').hide();
                // const container = d.querySelector(selector);
                // if (container) {
                //     container.innerHTML = "公開されている記事はありません。";
                // }
            }
        });
    }
}

const onLoadHtml = () => {
    let blocks = new Blocks();

    // チェックリストの項目全てを取得
    const checkListItems = document.querySelectorAll('.check-list__item');
    const resultButton = document.querySelector('._result-btn');
    const scoreElement = document.querySelector('._score');
    const commentElement = document.querySelector('._comment');

    checkListItems.forEach((item) => {
        item.addEventListener('click', () => {
            // チェックボックスをトグル
            const checkbox = item.querySelector("input[type='checkbox']");
            checkbox.checked = !checkbox.checked;
        });
    });

    // 結果ボタンをクリックした際の動作
    resultButton.addEventListener('click', () => {
        const checkedCount = document.querySelectorAll(
            ".check-list__item input[type='checkbox']:checked"
        ).length;
        scoreElement.textContent = checkedCount;

        if (checkedCount >= 7) {
            commentElement.textContent = '是非とも一緒に働きましょう！';
        } else {
            commentElement.textContent = '';
        }
    });
};

export default function RECRUIT() {
    // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
    d.addEventListener('rwd002.beforeDomready', (e) => domReady(e.detail));
    $(() => Promise.all([w.load['loadHtml']]).then(onLoadHtml));
}
