import Swiper from "swiper/bundle";
import GET_ARTICLES from "../class/getArticles";

const [d,mq] = [document,window.matchMedia( "(max-width: 1023px)" ) ];

class Blocks
{
  constructor()
  {
    this.init();
  }

  init()
  {
    this.headline();
    this.news('.block-news__list[data-plugin="getArticles"]');
  }

  mainvisual() {
    //メインスライダー
    new Swiper('.mainvisual-slider[data-plugin="mainSlider"]',{
      direction: 'vertical',
      loop: true,
      effect: 'fade',
      allowTouchMove: false,
      speed: 1200,
      fadeEffect: {
        crossFade: true, // クロスフェードを有効にする（フェードモードの場合 true 推奨）
      },
      autoplay:{
        delay: 10000,
        disableOnInteraction: false,
        waitForTransition: false
      },
      pagination: {
        el: ".mainvisual__slider-pager",
        clickable: true,
      },
    });
  }

  //headline
  headline() {
    const getArticle = new GET_ARTICLES('.mainvisual-headline__article[data-plugin="getArticles"]');
    getArticle.makeItem = (item, content) => `
      <a href="${item.href}" class="mainvisual-headline__link">
        <span class="date">${item.date}</span>
        <span class="cate">${item.category.name}</span>
        <h3 class="subject ${item.is_new ? 'is-new' : ''}">${item.title.str_truncate(15)}</h3>
        <span class="icon"></span>
      </a>
      `;
    getArticle.render();
  }

  news(selector)
  {
    const news_block = new GET_ARTICLES(selector);
    news_block.makeItem = (item,content) => `
    <li class="block-${content}-item">
      <a href="/${content}/?mode=detail&amp;article=${item.id}" class="block-${content}-item__link">
        <div class="block-${content}-item__body">
          <div class="date-cate">
            <span class="date">${item.date}</span><span class="cate">${item.category.name}</span>
          </div>
          <h4 class="title ${item.is_new? 'is-new':''}">${(mq.matches)? item.title.str_truncate(24) : item.title.str_truncate(40)}</h4>
        </div>
      </a>
    </li>
    `;
    // news_block.makeItem = (item) => console.log(item);
    news_block.render();
  }
  fetchCategories (content) {
    return fetch(`/assets/api/getCategories/?content=${content}`)
      .then(r => r.json())
      .catch(e => { throw new Error(e)});
  }

  fetchArticles (opt){
    return fetch(`/assets/api/getArticles/?${ new URLSearchParams(opt)}`)
    .then(r => r.json())
    .catch(e => { throw new Error(e)});
  }
}

const domReady = TPL =>
{
  let blocks = new Blocks;
  window.addEventListener('load', () => {
    blocks.mainvisual();
  });
};

const jqInit = () => false;

export default function HOME () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );

  $(() => jqInit());
}